import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Badge, Text, Heading, Select, Button } from 'theme-ui'
import { Link as GLink } from 'gatsby'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import styled from "styled-components"
import CardForm from '../CardForm/Block01'
import CreditCard from '../CreditCard/Block01'
import httpService from '../../../../../../site/src/service/httpService'
import { getPricing } from '../../../../../../site/src/service/auth'
import { FormContext } from '@solid-ui-components/ContentForm'
import useForm from '@helpers/useForm'



const StyledApp = styled.main`
display: flex;
flex-direction: row;
flex-wrap: wrap;
padding: 1em;
justify-content: space-around;

& > div:not(:last-child) {
  margin-bottom: 2em;
}
`

const StyledCard = styled.div`
font-family: "Space Mono", monospace;
text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
height: 200px;
width: 355px;
flex: 0 0 auto;
padding: 0 1em;

.card {
  height: 100%;
  border-radius: 8px;
  box-shadow: 1px 1px #aaa3a3;
  background: linear-gradient(45deg, #343a40, #666666, #343a40);
  color: #fff;

  .cardNumber {
    position: relative;
    top: 45px;
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    word-spacing: 4px;
    letter-spacing: 2px;
    padding: 0 1em;


    .numberSection{
      font-size: 23px;
    }
  }

  .cardInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    position: relative;
    top: 85px;
    padding: 0 1em;

    span {
      font-size: 11px;
    }

    p {
      margin-top: 8px;
      font-size: 16px;
    }

    .cardExpiry {
      text-align: right;
    }
  }
  
}
`;


const DonusBlock01 = ({ content, ...props }) => {

  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const formId = "frmOdeme";


  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, submitting, setFormValues])

  const handleChange = e => {
    const { name, value } = e.target;


    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [name]: value,

      }

    })

    setmsfliSati(!msfliSati);

  };


  const initialState = {
    name: "",
    number: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: ""
  };




  const inputReducer = (state, action) => {

    return { ...state, [action.key]: action.value };
  };

  const [cardInfo, handleOnChange] = React.useReducer(
    inputReducer,
    initialState
  );


  const [dt, setDt] = useState(null);

  const [paketAdi, setPaketAdi] = useState("");
  const [tutar, setTutar] = useState("");
  const [msfliSati, setmsfliSati] = useState(false);




  useEffect(async () => {
    const gp = await getPricing();
    if (gp) {
      if (gp.h < 100) {
        setPaketAdi(gp.h + " Kredili Paket");
      } else
        setPaketAdi(gp.pa);
      setTutar(gp.t);
      setDt(gp);
    } else {
      setDt(null);
    }
  }, []);


  return (
    <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
      <Box >

        {dt && <div style={{ margin: "20px" }}>
          Seçilen Paket:    <Text as="span" color="black">{paketAdi}  </Text>
          <Divider space="1" />
          Tutar:    <Text as="span" color="black" >{tutar}.00₺ (Kdv Dahil)  </Text>
          <Divider space="1" />

        </div>
        }
      </Box>
      <Box>
        <StyledCard>
          <div className="card">
            <Flex
              sx={{
                alignItems: `start`,
                justifyContent: `start`
              }}
            >
              <Box key={`item`} sx={{ p: 1, width: 250 }}>

              </Box>
              <Box key={`item`} sx={{ p: 1, width: 250 }}>

              </Box>
            </Flex>
            <Flex
              sx={{
                alignItems: `start`,
                justifyContent: `start`
              }}  >
              <Box key={`item`} sx={{ p: 1, width: 250 }}>

              </Box>
              <Box key={`item`} sx={{ p: 1, width: 250 }}>

              </Box>
            </Flex>
            <Flex
              sx={{
                alignItems: `start`,
                justifyContent: `start`
              }}
            >
              <Box key={`item`} sx={{ p: 1, width: 250 }}>

              </Box>
              <Box sx={{
                p: 4, alignItems: "center", justifyContent: "center", display: "flex",
                flexDirection: "column", width: 250
              }}>

                <Button type="submit"
                aria-label="ödeme tamamla"
                  id={"bnOde"} index={0} >
                  Ödemeyi Tamamla
                </Button>
              </Box>
            </Flex>
            <Flex
              sx={{
                alignItems: `start`,
                justifyContent: `start`
              }}
            >
              {/* <Text as="span" color="red"> {odemeMesaj} </Text> */}
            </Flex>
          </div>
        </StyledCard>
        {/* <StyledCardForm>
         
        </StyledCardForm> */}
      </Box>
    </Flex>
  )
};



export default WithDefaultContent(DonusBlock01)

